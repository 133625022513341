import React from 'react';
import { connect } from 'react-redux';
import { getCatalogMenuList } from '../redux/actions/menuActions';
import { getSearchItemsBox } from '../redux/actions/productActions';

// import axios from 'axios';
import SearchBox from './search/SearchBox';
import Filters from './Filters';
import Brand from './sidebarCategory/Brand';
import CategorySidebarTypes from './sidebarCategory/CategorySidebarTypes';
import {
    Route
} from 'react-router-dom';

import CategorySidebarItem from '../components/sidebarCategory/CategorySidebarItem';
import SearchBoxModal from '../components/search/SearchBoxModal';

import SearchBrand from '../components/search/SearchBrand';
import SearchCategories from '../components/search/SearchCategories';

import CurrencyMenuItem from './other/CurrencyMenuItem';
import VatMenuItem from './other/VatMenuItem';
import { globalStatistic } from '../redux/actions/otherActions';
import { withTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

class CategorySidebar extends React.Component {


    constructor(props) {
        super(props);
        let ls = new SecureLS({ encodingType: 'aes' });
        this.isLoggedIn = ls.get('userInfo');

        this.state = {
            menu: [],
            sidebar: 'filter',
            searchBox: 0,
            searchListBox: [],
            searchText: '',
        };
    };

    componentDidMount() {
        this.props.getCatalogMenuList();
    };


    // componentDidUpdate(prevProps) {
    //     // console.log(this.props.activeVat, 'state')
    //     // console.log(prevProps.activeVat, 'prevState')

    //     if (prevProps.activeVat !== this.props.activeVat) {
    //         // console.log(prevProps.activeVat, 'vat pokemons state has changed - previous properties.')
    //         // console.log(this.props.activeVat, 'vat pokemons state has changed - current properties.')
    //     }
    // }

    selectedSidebarCategoryType = (item) => {
        this.setState({
            sidebar: item,
        });
    };
    gatherStat = (type, value) => {
        const { globalStatistic } = this.props;
        globalStatistic(type, value);
    }
    callbackSearchBox = (searchBoxLength, valItem) => {
        if (searchBoxLength > 0) {
            this.setState({
                searchBox: 1,
            });
        } else {
            this.setState({
                searchBox: 0,
            });
        };

        if (searchBoxLength > 3) {
            this.props.getSearchItemsBox(valItem, '');
            this.gatherStat(valItem, 'SEARCH')
        } else {

            this.setState({
                searchBox: 0,
            });
            this.props.getSearchItemsBox(valItem, 'clear');
        };

        this.setState({
            searchText: valItem
        })

    };
    callbackSerachFunction = () => {
        this.setState({
            searchBox: 0,
        });
    };

    callbackSearchBoxSetModal = () => {
        // this.setState({
        //     searchBox: 1,
        // });
        // this.setState({
        //     searchBox: 1,
        // });
    };




    renderList() {
        const { location } = this.props;
        const { getB2bSettings } = this.props;
        const anCategoryForHide = getB2bSettings.items.anCategoryForHide || '';
        console.log(anCategoryForHide, "anCategoryForHide");
        let hiddenCategoryIds = [];
        // if (location.pathname.includes('.mk')) {
        //     //     catalogItemsList = this.props.catalogItems;
        //     // } else {
        //     //     catalogItemsList = this.props.catalogItems.filter((x) => x._id !== 13 && x._id !== 1);
        //     // }
        if (anCategoryForHide.trim() !== '') {
            //tumacim string ko ceo broj i zto pisem  .map(id => parseInt(id, 10));
            hiddenCategoryIds = anCategoryForHide.split(',').map(id => parseInt(id, 10));
            console.log(hiddenCategoryIds, "TEST");
        }
        let catalogItemsList = this.props.catalogItems;

        if (hiddenCategoryIds.length > 0) {
            // console.log("UDJE U > 0");
            catalogItemsList = catalogItemsList.filter(item => !hiddenCategoryIds.includes(item._id));
        }

        return catalogItemsList.map((item, key) => (
            <CategorySidebarItem key={key} item={item} />
        ));
    }
    searchBoxModalClosedFunciot = () => {
        this.setState({
            searchBox: 0,
            searchText: ''
        });
    }

    setShowSearchBoxFunction = () => { };

    setSearchNameFunctionSet = (valItem) => {
        this.setState({
            searchText: valItem
        })
    }


    render() {
        const { t } = this.props;
        return (

            <>
                <div className="category-content-sidebar d-none d-lg-block">
                    <div className="sidebar-fixed-menu">
                        <div className="navbar-category ">
                            <Route render={({ history }) => <SearchBox
                                setSearchNameFunction={this.setSearchNameFunctionSet}
                                setShowSearchBox={this.setShowSearchBoxFunction}
                                closeSearchModalFunction={this.callbackSerachFunction}
                                searchFunctionClick={this.callbackSearchBoxSetModal}
                                searchBoxFunction={this.callbackSearchBox}
                                history={history}
                                searchText={this.state.searchText}
                            ></SearchBox>}></Route>
                            {this.isLoggedIn && (
                                <>
                                    <div className='value-radio-items'>
                                        <div className="category-sidebar-value-item">
                                            <div className="category-sidebar-item-radio-name">
                                                {t('components:valuta')}
                                            </div>
                                            <div className="category-sidebar-item-radio-buttons">
                                                {this.props.currencyItems.map((item, key) => (
                                                    <CurrencyMenuItem key={key} item={item} isPriceInEur={this.props.activeCurrency._id} productItem={this.props.productItem} activeVat={this.props.activeVat} inputName={'1'} />
                                                ))}
                                            </div>
                                        </div>
                                        <div className="category-sidebar-value-price">
                                            <div className="category-sidebar-item-radio-name">
                                                {t('components:ceneIpdv')}
                                            </div>
                                            <div className="category-sidebar-item-radio-buttons">
                                                <VatMenuItem productItem={this.props.productItem} activeVat={this.props.activeVat} />
                                                {/* <VatMenuItem productItem={this.props.productItem} vatInputId={'test2'} radioLabel={'NE'} defaultVal={"0"} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <Route render={({ history }) => <CategorySidebarTypes setSidebarCategory={this.selectedSidebarCategoryType} history={history} ></CategorySidebarTypes>}></Route>
                            {
                                this.state.sidebar === 'category' ? (
                                    <div className="content-category-boxes">
                                        <ul className="menu-items">
                                            {this.renderList()}
                                        </ul>
                                    </div>
                                ) : this.state.sidebar === 'filter' ? (
                                    <div className="content-category-boxes">
                                        <Filters />
                                    </div>
                                ) : this.state.sidebar === 'brand' ? (
                                    <div className="content-category-boxes">
                                        <Brand />
                                    </div>
                                ) : (
                                    <div className="content-category-boxes">
                                        <div className="search-brands-block-content">
                                            <h3>Brendovi</h3>
                                            <div className="line-h5-filter"></div>
                                            <SearchBrand />
                                        </div>
                                        <div className="search-categories-block-content">
                                            <h3>Kategorije</h3>
                                            <div className="line-h5-filter"></div>
                                            <SearchCategories />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <SearchBoxModal
                    searchText={this.state.searchText}
                    items={this.props.getSearchItemsBoxList}
                    searchBoxModalFunction={this.callbackSerachFunction}
                    searchBoxModal={this.state.searchBox}
                    searchBoxModalClosed={this.searchBoxModalClosedFunciot}

                />
            </>
        );
    };

};

const mapStateToProps = (state) => {
    return {
        catalogItems: state.getCatalogMenuList,
        getSearchItemsBoxList: state.getSearchItemsBox,
        currencyItems: state.getCurrencyItems,
        activeCurrency: state.activeCurrency,
        activeVat: state.activeVat.item,
        productItem: state.productDetail.items[0],
    };
};

export default withTranslation('components')(connect(
    mapStateToProps,
    {
        getCatalogMenuList,
        getSearchItemsBox,
        globalStatistic
    }
)(CategorySidebar));